<div class="modal-confirmation modal--open">
  <div class="modal-confirmation__content">
    @if (data.subtitle) {
      <h3 class="modal-confirmation__subtitle" [innerHTML]="data.subtitle">
      </h3>
    }

    <h2 class="modal-confirmation__title" [innerHTML]="data.title">
    </h2>

    <div class="modal-confirmation__buttons">
      <button
        class="pi-btn"
        (click)="onConfirm(true)"
      >
        {{ data.aceptText ? data.aceptText : 'SI' }}
      </button>
      <button
        class="pi-btn pi-btn-yellow"
        (click)="onConfirm(false)"
      >
      {{ data.declineText ? data.declineText : 'NO' }}
      </button>
    </div>
  </div>
</div>
