import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../interfaces/confirmation-dialog-data';

export interface ConfirmModal {
  objetive: string;
  isConfirmed: boolean;
}

@Component({
  selector: 'app-modal-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-confirmation.component.html',
  styleUrl: './modal-confirmation.component.scss'
})

export class ModalConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  onConfirm(isConfirmed: boolean): void {
    this.dialogRef.close({ objetive: this.data.objetive, isConfirmed });
  }
}
